.teaser-container{
	max-width: 420px;
	border: 1px solid black;
	padding: 20px;
	margin-bottom: 30px;
	
	tbody{
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
