.deportes-container{
	min-height: 70vh;
	padding-top: 80px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	color: #222;

	&-img{
		img{
			max-width: 450px;
		}
	}
	&-content{
		max-width: 450px;
		padding-top: 20px;
		margin-bottom: 60px;
		@include from(m){
			margin-left: 20px;
		}
		&-title{
			&::after{
				content: '';
				display: block;
				margin-top: 30px;
				width: 40px;
				border-top: 2px solid #444;
			}
		}
		p{
			margin-bottom: 35px;
		}
	}
}
.main-container{
	position: relative;
	width: 100%;
}
.last-text{
	position: absolute;
	background-color: var(--header-secundary-color);
	width: 100%;
	bottom: -100px;
}

.games-container{
	max-width: 450px;
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	.game-item{
		display: block;
		@include from(m){
			width: calc(100% /3);
		}
		width: 100%;
		text-align: center;
		font-weight: bold;
		cursor: pointer;
		&:hover{
			text-decoration: none;
			color: #222;
		}
	}
}
.contact-container{
	color: #222;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding-bottom: 20px;
	&-item{
		width: 100%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		border: 1px solid black;
		margin-top: 20px;
		text-align: center;
		@include from(m){
			width: 40%;
			margin: 5%;
		}
		&-img{
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			img{
				width: 66px;
			}
		}
	}	
}

