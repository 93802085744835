.tns-single{
	& > div:nth-child(odd){
		background        : red;
	}
	& > div:nth-child(even){
		background        :green;
	}
	&__content{
		min-height: 25vh;
		display           : flex;
		justify-content   : center;
		flex-direction    : column;
		align-items       : center;
		span{color        : white;}
	}
}
