.terminos-container{
	min-height: 58vh;
	padding-top: 80px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	color: #222;

	&-img{
		img{
			max-width: 450px;
		}
	}
	&-content{
		max-width: 1200px;
		padding-top: 20px;
		margin-bottom: 60px;
		@include from(m){
			margin-left: 20px;
		}
		&-title{
			&::after{
				content: '';
				display: block;
				margin-top: 30px;
				width: 40px;
				border-top: 2px solid #444;
			}
		}
		p{
			margin-bottom: 35px;
		}
	}
}
