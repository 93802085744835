body {
	margin         	: var(--header-height) 0 0 0;
	min-height     	: calc(100vh - var(--header-height));
	background 		: var(--body-bg);
	display        	: flex;
	flex-direction 	: column;
}

.main-header{}
.main-container{
	.container{
		position: relative;
		width: 100%;
		height: 100%;
		max-width: 1200px;
		margin: 0 auto;
		&__slider{
			min-height: 25vh;
			background: red;
			@include from(l){
				margin-right: 250px;
			}
		}
		&__signup{
			width: 250px;
			min-height: 25vh;
			background: green;
			margin: 0 auto;
			@include from(l){
				position: absolute;
				right: 0;
				top: 0;
				
			}
		}
	}
}
.main-footer {
	background 		: $color-first;
	color      		: #FFF;
	font-size  		: fontSize(small);
	margin-top 		: 100px;
}
