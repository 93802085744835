.box-color{
	background: #cccccc;
	color: #666666;
	border: 1px solid white;
	padding: 5px 15px;
}

.section-divider{
	padding-bottom: 30px;
}
img {
	border: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}


.sidebar-first {
  margin-bottom : 1em;
}

.no-marging{
	margin-top: 0;
	margin-bottom: 0;
}

.bottom-space{
	margin-bottom: 1em;
}
.foot-icon{
	margin-left: 5px;
}
