.main-image{
	.main-image-image{
		height: 830px;
		img{
			object-fit: cover;
			vertical-align: middle;
			height: 100%;
		}
	}	
	&-text{
		padding: 30px 0;
		background-color:  var(--main-color);
		text-align: center;
		h2{
			margin-bottom: 0;
			font-size: 1rem;
			padding: 0 8%;
			@include from (l){
				font-size: 1.5rem;
			}
			
		}
	}
	&-list{
		margin: 80px 0;
	}
	&-list-container{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		a{
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			color: #222;
			width: 100%;
			cursor: pointer;

			@include from(m){
				width: 50%;
			}
			@include from(l){
				width: calc(100% / 3);
			}

			.item-img-container{
				display: flex;
				justify-content: center;
			}

			.item-img{
				border: 1px ;
				background-color: #f5f5f5;
				border-radius: 50%;
				width: 96px;
				height: 96px;
			}
			.item-text{
				margin-top: 15px;
				
			}
			&:hover{
				text-decoration: none;
			}
		}

	}
}
