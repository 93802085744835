.footer{
	.footer-container{
		display: flex;
		flex-direction: column;
		font-size: 14px;
		padding-bottom: 40px;
		@include from(m){
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
		}
		&-img{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.img{
				width: 100%;
				display: flex;
				justify-content: center;
			}
			img{
				max-width: 114px;
			}
		}
		&-links{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@include from(m){
				padding: 0 20px;
			}
			
			&-mains{
				margin-top: 20px;
			}
			&-secondary{
				margin-top: 20px;
			}
		}
		.main-link{
			margin-right: 10px;
			&:hover{
				color: white;
			}
		}
	}
	background-color: var(--main-color);
}
