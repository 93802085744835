.top-nav{
	border-bottom 	: 1px solid #DFE0E0;
	background    	: var(--main-color);
	position      	: fixed;
	top           	: 0;
	width         	: 100%;
	min-height: 100px;
	left          	: 0;
	display 		: flex;
	flex-wrap: wrap;
	justify-content : center;
	align-items: center;
	z-index: 1;

	&__container{
		display 		: flex;
		justify-content : space-between;
		height        	: 100px;
		width: 100%;
		max-width: 1200px;
		padding: 0 15px;
	}
	ul{
		list-style: none;
	}
	&__link{
		align-self: center;
	}
	&__logo{
		width: 150px;
		display: block;
		@include from(lg){
			height: auto
		}
	}
	&__menu{
		@include fromTo(s,lg){
			
			position 		: fixed;
			padding 		: 0 10px;
			height 			: 100vh;
			width 			: 260px;
			background 		: var(--main-color);
			transition 		: right .5s;
			overflow-y 		: auto;
			border-top 		: 1px solid #DFE0E0;
			border-left 	: 1px solid #DFE0E0;
			top 			: var(--header-height);
			right 			: -260px;
			&.is-active {
				right 		: 0;
			}
		}
		@include from(lg){
			display: flex;
			align-self: center;
			margin: 0;
			padding: 0;
		}
	}
	&__li{
		@include fromTo(s,lg){
			display: block;
			border-bottom: 1px solid #DFE0E0;
			padding: 10px 0;
			margin: 0;

		}
		margin-left: 1em;
		list-style-type: none;
		display: inline-block;
	}
	&__anchor{
		&:hover{
			color: white;
		}
		@include fromTo(s,lg){
			display: block;
		}

	}

	&__mb-iconMenu {
		position: relative;
		align-self: center;
		top: 3px;
		padding: 0;
		span{
			&.hamburger-inner{
				background: white;
				&::after{}
				&::before{}
				&::after, &::before{
					background: white;
				}
			}
		}
		&:focus {
			outline: 0 !important;
			-moz-outline-style: none !important;
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		@include from(lg){
			display: none;
		}
	}

}

.menu-button{
	margin-top: 10px;
	padding: 0px 0px;
	max-height: 40px;
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	.icon-menu-text{
		padding: 10px;
		font-size: 16px;
	}
	background-color: var(--header-secundary-color);
	@include from(l){
		margin-top: 30px;
		margin-left: 10px;
		.icon-menu-text{
			margin-top: 0px;
			font-size: 16px;
		}
		
	}
}

.modal-content{
	.modal-header{
		background-color: var(--header-secundary-color);
	}
	.modal-body{
		display: flex;
		justify-content: center;
	}
	button.btn-close{
		color: var(--main-color);
		background-color: var(--text-color);
	}
	background-color: var(--text-color);
	color: var(--main-color);
	.btn-primary{
		background-color: var(--header-secundary-color);
	}
}
.register-form{
	color: var(--main-color);
}
