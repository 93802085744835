.prefooter{
	padding: 80px 0;
	background-color: var(--main-color);
	background-size: cover;
	background-position: 0px 82.1429px;

	.prefooter-container{
		padding: 0 30px;

		@include from(m){
			padding: 0 0;
		}

		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;

		&-button{
			margin-top: 20px;
		}
	}
}
