//layout
:root {
  --header-height 		: 3rem;
  @include from(l) {
    --header-height 	: 4rem;
  }
	//native color vars
	// --color-first      	: #007BDF;
		--main-color: #010A5A;
  		--header-color: #222;
		--header-secundary-color: #3177B1;
		--text-color: #eee;
		--buttons-color: #210C3A

}
// Sass Color Vars
$color-first: var(--color-first);


*,
*::before,
*::after {
	box-sizing: border-box;
}

::selection {
	color: white;
	background: #00CBFF;
}

strong {
	font-weight 	: 700;
}
