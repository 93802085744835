.preheader-container{
	width: 100%;
	height: 44px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px;
	font-size: 24px;
	background-color: var(--text-color);
	color: var(--header-color);
	&-email{
		display: none;
		@include from(m){
			display: block;
		}
	}
	&-language{
		display: none;
		@include from(m){
			display: flex;
			a{
				margin-right: 5px;
			}
			img{
				max-height: 21px;
			}
		}
	}
}
.icon{
	margin-top: 2px;
	&-menu{
		
	}
}
