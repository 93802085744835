.desktop-form{
	display: none;
	@include from(m){
		display: block;
	}
}
.sign-up{
	margin-top: 20px;
	background-color: green;
	&:hover{
		background-color: var(--main-color);
	}
}
.mobile-form{
	display: block;
	@include from(m){
		display: none;
	}
}
