.parlay-container{
	background-color: white;
	&-table{
		padding: 20px;
		color: white;
		max-width: 350px;
		background-color: #222;
		margin-bottom: 20px;
	}
}
