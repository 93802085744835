.sign-up-button{
	padding: 10px 32px;
	border: 2px solid white;
	color: white;
	cursor: pointer;
	&:hover{
		color: white;
		text-decoration: none;
		background-color: var(--header-secundary-color);
	}
	&_pages{
		background-color: var(--main-color);
	}
}
